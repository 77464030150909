import { getHttpClient } from './client';

export const getAllPricingZones = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/zone-pricing';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getPricingZone = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/zone-pricing/' + data).then((response) => response.data);
};

export const savePricingZone = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/zone-pricing', 'POST', data).then(
    (response) => response.data
  );
};

export const updatePricingZone = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/zone-pricing', 'POST', data).then(
    (response) => response.data
  );
};

export const deletePricingZone = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/zone-pricing/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const getAllPricingZoneRelations = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/zone-pricing-relation';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getPricingZoneRelation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/zone-pricing-relation/' + data).then((response) => response.data);
};

export const savePricingZoneRelation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/zone-pricing-relation', 'POST', data).then(
    (response) => response.data
  );
};

export const updatePricingZoneRelation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/zone-pricing-relation', 'POST', data).then(
    (response) => response.data
  );
};

export const deletePricingZoneRelation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/zone-pricing-relation/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const calculatePriceViaZonePricing = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/get-prices-by-coordinates';

  let queryString = '?';
  if (data) {
    if (data.from_lat) {
      queryString += `from_lat=${data.from_lat}`;
    }
    if (data.from_lng) {
      queryString += `&from_lng=${data.from_lng}`;
    }
    if (data.to_lat) {
      queryString += `&to_lat=${data.to_lat}`;
    }
    if (data.to_lng) {
      queryString += `&to_lng=${data.to_lng}`;
    }
    if (data.currency_id) {
      queryString += `&currency_id=${data.currency_id}`;
    }
    if (data.vehicle_class_id) {
      queryString += `&vehicle_class_id=${data.vehicle_class_id}`;
    }
  }

  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};