<template>
  <v-container class="pa-7">
    <v-dialog v-model="smsDialog" max-width="500" :key="smsDialog">
      <validation-observer ref="smsForm" v-slot="{ handleSubmit, failed }">
        <v-form lazy-validation @submit.prevent="handleSubmit(sendSms)">
          <v-card>
            <v-toolbar class="text-h5 primary" dark>
              {{ $t('driving.sendSms') }}
              <v-spacer></v-spacer>
              <v-icon class="mdi mdi-close mx-1" style="color: white" @click="smsDialog = false"></v-icon>
            </v-toolbar>
            <v-card-text>
              <br />
              <validation-provider rules="required|max:160" v-slot="{ errors }" name="message">
                <v-textarea
                  autofocus
                  v-model="smsItem.message"
                  clearable
                  counter="160"
                  :label="$t('driving.message')"
                  rows="6"
                  auto-grow
                  :error-messages="errors"></v-textarea>
              </validation-provider>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <button-submit :failed="failed" :loading="loading" buttonText="buttons.send"></button-submit>
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>

    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12">
        <v-card v-for="(item, index) in newPassengers" :key="index" class="px-5 my-5">
          <v-row>
            <v-col cols="12" md="12" sm="12" class="d-flex align-center">
              <span> #{{ index + 1 }} </span>
              <v-spacer />
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    text
                    v-bind="attrs"
                    v-on="on"
                    @click="handleSmsClick(item)"
                    :disabled="!disable || !item.phone">
                    <v-icon>mdi-message-text-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('tooltips.sendSms') }}</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="error" text v-bind="attrs" v-on="on" @click="deletePassenger(item)" :disabled="disable">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('tooltips.delete') }}</span>
              </v-tooltip>
            </v-col>

            <v-col cols="12" lg="4" md="4" sm="6">
              <validation-provider
                rules="max:200"
                v-slot="{ errors }"
                :name="'first_name' + index"
                :custom-messages="{
                  max: $t('customValidations.fieldLengthMax', {
                    field: $t('names.first_name'),
                    length: 200,
                  }),
                }">
                <v-text-field
                  v-model="item.first_name"
                  clearable
                  counter="200"
                  name="first_name"
                  :label="$t('passengersHeaders.firstName')"
                  :disabled="disable"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" lg="4" md="4" sm="6">
              <validation-provider
                rules="max:200"
                v-slot="{ errors }"
                :name="'last_name' + index"
                :custom-messages="{
                  max: $t('customValidations.fieldLengthMax', {
                    field: $t('names.last_name'),
                    length: 200,
                  }),
                }">
                <v-text-field
                  v-model="item.last_name"
                  clearable
                  counter="200"
                  name="last_name"
                  :label="$t('passengersHeaders.lastName')"
                  :disabled="disable"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" lg="4" md="4" sm="6">
              <validation-provider
                :rules="{
                  regex: /^\+?[0-9\s\-()]+$/,
                  max: 200,
                }"
                v-slot="{ errors }"
                :name="'phone_number' + index"
                :custom-messages="{
                  regex: $t('customValidations.fieldType', {
                    field: $t('names.phone_number'),
                    type: $t('names.phone_number'),
                  }),
                  max: $t('customValidations.fieldLengthMax', {
                    field: $t('names.phone_number'),
                    length: 200,
                  }),
                }">
                <v-text-field
                  v-model="item.phone"
                  clearable
                  counter="200"
                  name="phone"
                  :label="$t('passengersHeaders.phoneNumber')"
                  :disabled="disable"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" lg="4" md="4" sm="6">
              <validation-provider
                rules="email|max:200"
                v-slot="{ errors }"
                :name="'email' + index"
                :custom-messages="{
                  email: $t('customValidations.fieldType', {
                    field: $t('names.email'),
                    type: $t('names.email'),
                  }),
                  max: $t('customValidations.fieldLengthMax', {
                    field: $t('names.email'),
                    length: 200,
                  }),
                }">
                <v-text-field
                  v-model="item.email"
                  clearable
                  counter="200"
                  name="email"
                  :label="$t('passengersHeaders.email')"
                  :disabled="disable"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" lg="4" md="4" sm="6">
              <validation-provider
                rules="max:200"
                v-slot="{ errors }"
                :name="'passport' + index"
                :custom-messages="{
                  max: $t('customValidations.fieldLengthMax', {
                    field: $t('names.passport'),
                    length: 200,
                  }),
                }">
                <v-text-field
                  v-model="item.passport"
                  clearable
                  counter="200"
                  name="passport"
                  :label="$t('passengersHeaders.passport')"
                  :disabled="disable"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" lg="4" md="4" sm="6">
              <validation-provider
                rules="max:200"
                v-slot="{ errors }"
                :name="'nationality' + index"
                :custom-messages="{
                  max: $t('customValidations.fieldLengthMax', {
                    field: $t('names.nationality'),
                    length: 200,
                  }),
                }">
                <v-text-field
                  v-model="item.nationality"
                  clearable
                  counter="200"
                  name="nationality"
                  :label="$t('passengersHeaders.nationality')"
                  :disabled="disable"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-spacer></v-spacer>
        <v-btn color="primary cancel" text @click="addNew" :disabled="disable">
          {{ $t('driving.addPassenger') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="passengerDialogDelete" max-width="650">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{
            passengerToDelete?.url
              ? passengerToDelete?.url?.slice(19)
              : passengerToDelete?.first_name + ' ' + passengerToDelete?.last_name
          }}
          <v-spacer></v-spacer>
          <v-icon class="mdi mdi-close" style="color: white" @click="passengerDialogDelete = false"></v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <p>
              {{ $t('driving.deletePassenger') }}
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" text @click="deletePassengerConfirm">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { defaultSmsItem } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';

export default {
  name: 'AddPassengers',
  props: ['editedDrivingItem', 'headers', 'passengers', 'disable', 'drivers'],
  components: { ButtonSubmit },
  data: () => ({
    defaultItem: {
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      nationality: '',
      passport: '',
    },
    newPassengers: [],
    passengerDialogDelete: false,
    passengerToDelete: {},
    indexOfPassengerToDelete: null,
    formValid: true,
    loading: false,
    smsDialog: false,
    smsItem: {},
  }),

  created() {
    this.newPassengers = [...this.passengers];
    this.smsItem = Object.assign(
      {},
      {
        ...defaultSmsItem,
        message: `Reservation ${this.editedDrivingItem.number} update:\nDriver is at the location.\nDriver: ${
          this.editedDrivingItem?.drivingProposal?.user_id
            ? this.getDriversName(this.editedDrivingItem?.drivingProposal?.user_id)
            : '/'
        }\nVehicle: ${this.editedDrivingItem.vehicle ? this.getVehicleName(this.editedDrivingItem.vehicle) : '/'}\n`,
      }
    );
  },

  methods: {
    addNew() {
      const addObj = Object.assign({}, this.defaultItem);
      this.newPassengers.push(addObj);
      this.$emit('updatePassengers', this.newPassengers);
      this.$emit('checkSeatsAvailability', this.newPassengers);
    },
    deletePassenger(passenger) {
      const index = this.newPassengers.indexOf(passenger);
      this.passengerToDelete = passenger;
      this.indexOfPassengerToDelete = index;
      this.passengerDialogDelete = true;
    },
    async deletePassengerConfirm() {
      if (this.passengerToDelete?.id) {
        await this.$store
          .dispatch('drivings/deleteDrivingPassenger', this.passengerToDelete)
          .then(() => {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
            this.passengerDialogDelete = false;
            this.$emit('deletePassenger', this.indexOfPassengerToDelete);
          })
          .catch(() => {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          });
      } else {
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
        this.passengerDialogDelete = false;
        this.$emit('deletePassenger', this.indexOfPassengerToDelete);
      }
    },
    handlePassengerChange() {
      this.$emit('updatePassengers', this.newPassengers);
    },
    handleSmsClick(item) {
      this.smsDialog = true;
      this.smsItem.phone_number = item.phone;
    },
    async sendSms() {
      this.loading = true;
      let formData = this.createFormData();

      await this.$store
        .dispatch('drivings/sendSms', formData)
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.sentSuccessfully'), color: 'green' });
          this.smsDialog = false;
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    createFormData() {
      let formData = new FormData();

      formData.append('message', this.smsItem.message ? this.smsItem.message : '');
      formData.append('phone_number', this.smsItem.phone_number ? this.smsItem.phone_number : '');

      return formData;
    },
    getDriversName(item) {
      let index = this.drivers.findIndex((x) => x.id == item);
      return this.drivers[index]?.profile?.phone
        ? this.drivers[index]?.full_name + ' (' + this.drivers[index]?.profile?.phone + ')'
        : this.drivers[index]?.full_name;
    },
    getVehicleName(item) {
      return item.plate_number ? item.brand + ' (' + item.plate_number + ')' : item.brand;
    },
  },

  watch: {
    passengers: function (value) {
      this.newPassengers = [...value];
    },
    smsDialog(val) {
      if (!val) {
        this.$refs.smsForm.reset();
        this.smsItem = Object.assign(
          {},
          {
            ...defaultSmsItem,
            message: `Reservation ${this.editedDrivingItem.number} update:\nDriver is at the location.\nDriver: ${
              this.editedDrivingItem?.drivingProposal?.user_id
                ? this.getDriversName(this.editedDrivingItem?.drivingProposal?.user_id)
                : '/'
            }\nVehicle: ${
              this.editedDrivingItem.vehicle ? this.getVehicleName(this.editedDrivingItem.vehicle) : '/'
            }\n`,
          }
        );
      }
    },
  },
};
</script>

<style lang="scss">
.mobile-table {
  display: none;
}

@media only screen and (max-width: 599px) {
  .large-table {
    display: none;
  }

  .mobile-table {
    display: unset;

    th,
    td {
      width: 50%;
    }
  }
}
</style>
