import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './styles/main.scss';
import i18n from './i18n/i18n';
import vuetify from './plugins/vuetify';
import * as VueGoogleMaps from 'gmap-vue';
import './validations/validations';
import VueMeta from 'vue-meta';
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import VueTour from 'vue-tour';
import vClickOutside from 'v-click-outside';
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2';

import Hotjar from '@hotjar/browser';
import VueTheMask from 'vue-the-mask';

import { VueReCaptcha } from 'vue-recaptcha-v3';

Vue.use(VueReCaptcha, { siteKey: '6LfaB4InAAAAALlbd2SvtPUgyvz1f3NsouCqVY_c' });
// Your site ID from Hotjar
const siteId = process.env.VUE_APP_HOTJAR_SITE_ID; // Replace with your actual site ID

// Initialize Hotjar
Hotjar.init(siteId, 6); // Replace 6 with the version number provided by Hotjar if different

Vue.use(VueMeta);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: ['places', 'drawing', 'routes'],
  },
  installComponents: true,
});
Vue.use(VueTour);

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: 'AIzaSyB6XqxdwHQ9GGYAdZ-6Sk3oM3A23mZ7wU0',
  authDomain: 'limoexpress-e1849.firebaseapp.com',
  projectId: 'limoexpress-e1849',
  storageBucket: 'limoexpress-e1849.appspot.com',
  messagingSenderId: '830423176156',
  appId: '1:830423176156:web:573d57c409c6f9d9c5f519',
  measurementId: 'G-21M42QM5GK',
};

// Check if the browser supports necessary features for Firebase Messaging
const isMessagingSupported = 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;

if (isMessagingSupported) {
  try {
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    Notification.requestPermission()
      .then((allowed) => {
        if (allowed === 'granted') {
          getToken(messaging, {
            vapidKey: 'BKl1zo_4SnG6cBAzSnThsGw98U_jVGN1NW2-syrPAlRuAD93Y_aR-na6qTf8TaAxR6lbBSNb1xpkNykvsl6iFhE',
          })
            .then((token) => {
              Vue.prototype.$deviceToken = token;
            })
            .catch(() => {
              getToken(messaging, {
                vapidKey: 'BKl1zo_4SnG6cBAzSnThsGw98U_jVGN1NW2-syrPAlRuAD93Y_aR-na6qTf8TaAxR6lbBSNb1xpkNykvsl6iFhE',
              })
                .then((token) => {
                  Vue.prototype.$deviceToken = token;
                })
                .catch((err) => {
                  // eslint-disable-next-line no-console
                  console.error('Error getting token:', err);
                });
            });
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Error requesting permission:', err);
      });

    onMessage(messaging, (payload) => {
      const noteTitle = payload.notification.title;
      const noteOptions = {
        body: payload.notification.body,
        icon: 'favicon.ico',
        vibrate: 5,
      };
      let notification = new Notification(noteTitle, noteOptions);
      notification.addEventListener('click', () => {
        if (router.history.current.name === 'CalendarView') {
          history.pushState({}, '', `/?driving_id=${payload.data.driving_id}`);
          store.commit('updateNotificationDriving', payload.data.driving_id);
        } else {
          router.push({
            name: 'CalendarView',
            query: { driving_id: payload.data.driving_id },
          });
        }
      });
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Firebase initialization error:', error);
  }
} else {
  // eslint-disable-next-line no-console
  console.warn('Firebase Messaging is not supported in this browser. Some features may not work as expected.');
}

Vue.use(TawkMessengerVue, {
  propertyId: '64f5b9b5b2d3e13950edce83',
  widgetId: '1h9ftqr89',
});

Vue.use(VueTheMask);
Vue.use(vClickOutside);

const VueApp = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

export default VueApp;

// Hide chat widget on public routes
const routesToHideChatWidget = [
  '/login',
  '/set-password',
  '/change-password',
  '/forget-password',
  '/social-login',
  '/registration',
  '/public/reservation-form',
];

function toggleChatWidget(route) {
  const shouldShowChatWidget = !routesToHideChatWidget.includes(route);

  if (store.getters['auth/role'] === 1 && shouldShowChatWidget) {
    VueApp.$tawkMessenger.showWidget();
  } else {
    if (shouldShowChatWidget) {
      VueApp.$tawkMessenger.showWidget();
    } else {
      VueApp.$tawkMessenger.hideWidget();
    }
  }
}

VueApp.$tawkMessenger.$on('load', () => {
  toggleChatWidget(router.history.current.path);

  router.beforeEach((to, from, next) => {
    toggleChatWidget(to.path);

    next();
  });
});

// import router from './router'; // Assuming you have a router.js file exporting Vue Router instance

router.afterEach((to) => {
  // Trigger the page view manually
  Hotjar.stateChange(to.fullPath); // Use `to.path` if you don't want query parameters to be included
});
