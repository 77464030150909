export function usersPageHeaders(i18n, data) {
  const headers = [
    { text: i18n.t('usersPageHeaders.firstName'), value: 'profile.first_name' },
    { text: i18n.t('usersPageHeaders.lastName'), value: 'profile.last_name' },
    { text: i18n.t('usersPageHeaders.username'), value: 'username' },
    { text: i18n.t('usersPageHeaders.email'), value: 'email' },
    { text: i18n.t('usersPageHeaders.phoneNumber'), value: 'profile.phone' },
    { text: i18n.t('usersPageHeaders.role'), value: 'role.name' },
    {
      text: i18n.t('usersPageHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];

  return headers.filter((header) => !data.hiddenColumns.includes(header.value));
}

export function masterUsersPageHeaders(i18n) {
  return [
    {
      text: i18n.t('masterUsersPageHeaders.firstName'),
      value: 'profile.first_name',
    },
    {
      text: i18n.t('masterUsersPageHeaders.lastName'),
      value: 'profile.last_name',
    },
    { text: i18n.t('masterUsersPageHeaders.username'), value: 'username' },
    { text: i18n.t('masterUsersPageHeaders.email'), value: 'email' },
    {
      text: i18n.t('masterUsersPageHeaders.phoneNumber'),
      value: 'profile.phone',
    },
    {
      text: i18n.t('masterUsersPageHeaders.organisation'),
      value: 'organisation.name',
    },
    {
      text: i18n.t('masterUsersPageHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function clientsPageHeaders(i18n) {
  return [
    { text: i18n.t('clientsPageHeaders.name'), value: 'name' },
    { text: i18n.t('clientsPageHeaders.address'), value: 'address' },
    { text: i18n.t('clientsPageHeaders.phoneNumber'), value: 'phone' },
    { text: i18n.t('clientsPageHeaders.email'), value: 'email' },
    { text: i18n.t('clientsPageHeaders.companyId'), value: 'pib' },
    { text: i18n.t('clientsPageHeaders.clientType'), value: 'type' },
    { text: i18n.t('clientsPageHeaders.percentage'), value: 'percent' },
    {
      text: i18n.t('clientsPageHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function drivingsHeaders(i18n) {
  return [
    { text: i18n.t('drivingsHeaders.drivingNumber'), value: 'driving_number' },
    { text: i18n.t('drivingsHeaders.drivingType'), value: 'drivingType.name' },
    {
      text: i18n.t('drivingsHeaders.drivingStatus'),
      value: 'drivingProposal.drivingProposalStatus',
    },
    {
      text: i18n.t('drivingsHeaders.locationFrom'),
      value: 'from_location_name',
    },
    { text: i18n.t('drivingsHeaders.locationTo'), value: 'to_location_name' },
    {
      text: i18n.t('drivingsHeaders.vehicleClass'),
      value: 'vehicleClass.name',
    },
    { text: i18n.t('drivingsHeaders.vehicle'), value: 'vehicle.name' },
    { text: i18n.t('drivingsHeaders.driversName'), value: 'user_id' },
    { text: i18n.t('drivingsHeaders.startTime'), value: 'pickup_time' },
    {
      text: i18n.t('drivingsHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function drivingsBasicHeaders(i18n) {
  return [
    { text: i18n.t('drivingsHeaders.drivingNumber'), value: 'number' },
    {
      text: i18n.t('drivingsHeaders.locationFrom'),
      value: 'from_location_name',
    },
    { text: i18n.t('drivingsHeaders.locationTo'), value: 'to_location_name' },
    { text: i18n.t('drivingsHeaders.vehicle'), value: 'vehicle.name' },
    { text: i18n.t('drivingsHeaders.driversName'), value: 'user_id' },
    { text: i18n.t('drivingsHeaders.startTime'), value: 'pickup_time' },
    {
      text: i18n.t('drivingsHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function customersDrivingsHeaders(i18n) {
  return [
    { text: i18n.t('drivingsHeaders.drivingNumber'), value: 'number' },
    {
      text: i18n.t('drivingsHeaders.totalPrice'),
      value: 'price',
    },
    { text: i18n.t('drivingsHeaders.startTime'), value: 'pickup_time' },
    {
      text: i18n.t('drivingsHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function vehiclesHeaders(i18n, store) {
  return [
    { text: i18n.t('vehiclesHeaders.plateNumber'), value: 'plate_number' },
    { text: i18n.t('vehiclesHeaders.brand'), value: 'brand' },
    {
      text: i18n.t('vehiclesHeaders.vehicleClass'),
      value: 'vehicleClass.name',
    },
    {
      text: i18n.t('vehiclesHeaders.passengersNumber'),
      value: 'number_of_passengers',
    },
    {
      text: i18n.t('vehiclesHeaders.fuelConsumption'),
      value: 'fuel_consumption',
    },
    {
      text: i18n.t('vehiclesHeaders.yearOfManufacture'),
      value: 'year_of_manufacture',
    },
    {
      text:
        store.getters['auth/user'].organisation?.organisation_settings?.distance_unit == 'mi'
          ? i18n.t('vehiclesHeaders.pricePerMi')
          : i18n.t('vehiclesHeaders.pricePerKm'),
      value: 'price_per_km',
    },
    { text: i18n.t('vehiclesHeaders.pricePerHour'), value: 'price_per_hour' },
    {
      text: i18n.t('vehiclesHeaders.pricePerWaitingHour'),
      value: 'price_per_waiting_hour',
    },
    {
      text: i18n.t('vehiclesHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function expensesHeaders(i18n) {
  return [
    { text: i18n.t('expensesHeaders.name'), value: 'name' },
    { text: i18n.t('expensesHeaders.vehicle'), value: 'vehicle.name' },
    { text: i18n.t('expensesHeaders.type'), value: 'expense_type.name' },
    { text: i18n.t('expensesHeaders.driving'), value: 'driving.number' },

    {
      text: i18n.t('expensesHeaders.description'),
      value: 'description',
    },
    { text: i18n.t('expensesHeaders.price'), value: 'price' },
    {
      text: i18n.t('expensesHeaders.expenseDate'),
      value: 'expense_date',
    },
    {
      text: i18n.t('expensesHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function vehicleMaintenanceHeaders(i18n) {
  return [
    { text: i18n.t('vehicleMaintenanceHeaders.name'), value: 'vehicle.name' },
    { text: i18n.t('vehicleMaintenanceHeaders.details'), value: 'note' },
    { text: i18n.t('vehicleMaintenanceHeaders.dateFrom'), value: 'date_from' },
    { text: i18n.t('vehicleMaintenanceHeaders.dateTo'), value: 'date_to' },
    {
      text: i18n.t('vehicleMaintenanceHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function vehicleClassesHeaders(i18n) {
  return [
    { text: i18n.t('vehicleClassesHeaders.vehicleClass'), value: 'name' },
    {
      text: i18n.t('vehicleClassesHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function rebatesHeaders(i18n) {
  return [
    { text: i18n.t('rebatesHeaders.min'), value: 'min' },
    { text: i18n.t('rebatesHeaders.max'), value: 'max' },
    { text: i18n.t('rebatesHeaders.percentage'), value: 'percentage' },
    {
      text: i18n.t('rebatesHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function pricingRegionsHeaders(i18n) {
  return [
    { text: i18n.t('pricingRegionsHeaders.name'), value: 'name' },
    {
      text: i18n.t('pricingRegionsHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function notificationsHeaders(i18n) {
  return [
    { text: i18n.t('notificationsHeaders.time'), value: 'time' },
    {
      text: i18n.t('notificationsHeaders.notification'),
      value: 'notification',
    },
  ];
}

export function organisationsHeaders(i18n) {
  return [
    { text: i18n.t('organisationsHeaders.name'), value: 'name' },
    { text: i18n.t('organisationsHeaders.phoneNumber'), value: 'phone_number' },
    { text: i18n.t('organisationsHeaders.email'), value: 'email' },
    { text: i18n.t('organisationsHeaders.address'), value: 'address' },
    { text: i18n.t('organisationsHeaders.companyId'), value: 'company_id' },
    // { text: i18n.t('organisationsHeaders.vat'), value: 'vat' },
    { text: i18n.t('organisationsHeaders.createdAt'), value: 'created_at' },
    { text: i18n.t('organisationsHeaders.billingDate'), value: 'billing_date' },
    {
      text: i18n.t('organisationsHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function transactionsHeaders(i18n) {
  return [
    { text: i18n.t('transactionsHeaders.invoiceNumber'), value: 'invoice_number' },
    { text: i18n.t('transactionsHeaders.status'), value: 'status' },
    { text: i18n.t('transactionsHeaders.total'), value: 'total' },
    { text: i18n.t('transactionsHeaders.currency'), value: 'currency' },
    { text: i18n.t('transactionsHeaders.billedAt'), value: 'billed_at' },
    {
      text: i18n.t('transactionsHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function currenciesHeaders(i18n) {
  return [
    { text: i18n.t('currenciesHeaders.name'), value: 'name' },
    { text: i18n.t('currenciesHeaders.code'), value: 'code' },
    { text: i18n.t('currenciesHeaders.symbol'), value: 'symbol' },
    {
      text: i18n.t('currenciesHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function organisationCurrenciesHeaders(i18n) {
  return [
    { text: i18n.t('currenciesHeaders.name'), value: 'currency.name' },
    { text: i18n.t('currenciesHeaders.code'), value: 'currency.code' },
    { text: i18n.t('currenciesHeaders.symbol'), value: 'currency.symbol' },
    { text: i18n.t('currenciesHeaders.default'), value: 'default' },
    {
      text: i18n.t('currenciesHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function paymentMethodsHeaders(i18n) {
  return [
    { text: i18n.t('paymentMethodsHeaders.name'), value: 'name' },
    { text: i18n.t('paymentMethodsHeaders.default'), value: 'default' },
    { text: i18n.t('paymentMethodsHeaders.priceVisible'), value: 'visible' },
    {
      text: i18n.t('paymentMethodsHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function feedbacksHeaders(i18n) {
  return [
    { text: i18n.t('feedbacksHeaders.subject'), value: 'subject' },
    {
      text: i18n.t('feedbacksHeaders.organisation'),
      value: 'user.organisation.name',
    },
    { text: i18n.t('feedbacksHeaders.user'), value: 'user.profile.full_name' },
    {
      text: i18n.t('feedbacksHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function checkpointsHeaders(i18n) {
  return [
    {
      text: i18n.t('checkpointsHeaders.checkpointLocation'),
      value: 'checkpoint_location',
      sortable: false,
    },
    {
      text: i18n.t('checkpointsHeaders.arrivalTime'),
      value: 'arrival_time',
      sortable: false,
    },
    {
      text: i18n.t('checkpointsHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
      width: '100px',
    },
  ];
}

export function passengersHeaders(i18n) {
  return [
    {
      text: i18n.t('passengersHeaders.firstName'),
      value: 'first_name',
      sortable: false,
    },
    {
      text: i18n.t('passengersHeaders.lastName'),
      value: 'last_name',
      sortable: false,
    },
    {
      text: i18n.t('passengersHeaders.phoneNumber'),
      value: 'phone',
      sortable: false,
    },
    {
      text: i18n.t('passengersHeaders.email'),
      value: 'email',
      sortable: false,
    },
    {
      text: i18n.t('passengersHeaders.passport'),
      value: 'passport',
      sortable: false,
    },
    {
      text: i18n.t('passengersHeaders.nationality'),
      value: 'nationality',
      sortable: false,
    },
    {
      text: i18n.t('passengersHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
      width: '100px',
    },
  ];
}

export function costEffectivenessReportHeaders(i18n, filterOption) {
  let colNumber;

  colNumber = filterOption === 1 ? 3 : 4;
  const headers = [{ text: i18n.t('reportsHeaders.vehicle'), value: 'vehicleName' }];

  for (let i = 0; i < colNumber; i++) {
    headers.push(
      { text: i18n.t('reportsHeaders.earnings'), value: `data[${i}].earnings` },
      { text: i18n.t('reportsHeaders.expenses'), value: `data[${i}].cost` },
      { text: i18n.t('reportsHeaders.profit'), value: `data[${i}].profit` }
    );
  }

  return headers;
}

export function utilizationReportHeaders(i18n, filterOption) {
  let colNumber;
  colNumber = filterOption === 1 ? 3 : 4;
  const headers = [{ text: i18n.t('reportsHeaders.vehicle'), value: 'vehicleName' }];

  for (let i = 0; i < colNumber; i++) {
    headers.push(
      { text: i18n.t('reportsHeaders.daysWorking'), value: `data[${i}].days_working` },
      { text: i18n.t('reportsHeaders.usageIndex'), value: `data[${i}].usage_index` }
    );
  }

  return headers;
}

export function clientsReportHeaders(i18n, filterOption) {
  let colNumber;
  colNumber = filterOption === 1 ? 3 : 4;
  const headers = [{ text: i18n.t('reportsHeaders.client'), value: 'client_name' }];

  for (let i = 0; i < colNumber; i++) {
    headers.push(
      { text: i18n.t('reportsHeaders.numberOfDrivings'), value: `data[${i}].number_of_drivings` },
      { text: i18n.t('reportsHeaders.percentForClient'), value: `data[${i}].percent_for_client` },
      { text: i18n.t('reportsHeaders.sumOfPrice'), value: `data[${i}].sum_of_price` }
    );
  }

  return headers;
}

export function cardsHeaders(i18n) {
  return [
    { text: i18n.t('customerCardsHeaders.cardholderName'), value: 'card_holder_name' },
    { text: i18n.t('customerCardsHeaders.cardNumber'), value: 'card_number' },
    { text: i18n.t('customerCardsHeaders.expiryDate'), value: 'expiry_date' },
    { text: i18n.t('customerCardsHeaders.cvv'), value: 'cvv' },
    {
      text: i18n.t('customerCardsHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function invoicesHeaders(i18n, data) {
  const headers = [
    { text: i18n.t('invoicesHeaders.invoiceNumber'), value: 'number' },
    { text: i18n.t('invoicesHeaders.client'), value: 'client.name' },
    { text: i18n.t('invoicesHeaders.totalPrice'), value: 'total_price' },
    { text: i18n.t('invoicesHeaders.drivings'), value: 'drivings' },
    { text: i18n.t('invoicesHeaders.paid'), value: 'paid' },
    { text: i18n.t('invoicesHeaders.currency'), value: 'currency.name' },
    { text: i18n.t('invoicesHeaders.createdAt'), value: 'created_at' },
    {
      text: i18n.t('invoicesHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];

  return headers.filter((header) => !data.hiddenColumns.includes(header.value));
}

export function drivingOffersHeaders(i18n) {
  return [
    { text: i18n.t('drivingOffersHeaders.number'), value: 'number' },
    { text: i18n.t('drivingOffersHeaders.totalPrice'), value: 'total_price' },
    { text: i18n.t('drivingOffersHeaders.description'), value: 'description' },
    { text: i18n.t('drivingOffersHeaders.paid'), value: 'paid' },
    { text: i18n.t('drivingOffersHeaders.currency'), value: 'currency.name' },
    { text: i18n.t('drivingOffersHeaders.createdAt'), value: 'created_at' },
    {
      text: i18n.t('drivingOffersHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function drivingInNext3DaysHeaders(i18n) {
  return [
    { text: i18n.t('drivingsHeaders.drivingNumber'), value: 'number' },
    { text: i18n.t('drivingsHeaders.price'), value: 'price' },
    {
      text: i18n.t('drivingsHeaders.locationFrom'),
      value: 'from_location_name',
    },
    { text: i18n.t('drivingsHeaders.vehicle'), value: 'vehicle.name' },
  ];
}

export function drivingNotPaidOrConfirmedHeaders(i18n) {
  return [
    { text: i18n.t('drivingsHeaders.drivingNumber'), value: 'number' },
    { text: i18n.t('drivingsHeaders.paid'), value: 'paid' },
    { text: i18n.t('drivingsHeaders.confirmed'), value: 'client_confirmed' },
  ];
}

export function driversInNext5DaysHeaders(i18n) {
  return [
    { text: i18n.t('drivingsHeaders.driversName'), value: 'full_name' },
    { text: i18n.t('drivingsHeaders.numberOfDrivings'), value: 'num_of_drivings' },
  ];
}

export function passengerReviewsLast5Headers(i18n) {
  return [
    { text: i18n.t('reviewsHeaders.rating'), value: 'rating' },
    { text: i18n.t('drivingsHeaders.drivingNumber'), value: 'driving.number' },
    { text: i18n.t('reviewsHeaders.comment'), value: 'comment' },
  ];
}

export function resourcesHeaders(i18n) {
  return [
    { text: i18n.t('resourceOverviewHeaders.actionType'), value: 'action_type' },
    { text: i18n.t('resourceOverviewHeaders.user'), value: 'user.profile.full_name' },
    { text: i18n.t('resourceOverviewHeaders.action'), value: 'body' },
    { text: i18n.t('resourceOverviewHeaders.createdAt'), value: 'created_at' },
  ];
}

export function passengerReviewsHeaders(i18n) {
  return [
    { text: i18n.t('reviewsHeaders.rating'), value: 'rating' },
    { text: i18n.t('drivingsHeaders.drivingNumber'), value: 'driving.number' },
    { text: i18n.t('reviewsHeaders.comment'), value: 'comment' },
    { text: i18n.t('reviewsHeaders.createdAt'), value: 'created_at' },
  ];
}

export function affiliateHeaders(i18n) {
  return [
    { text: i18n.t('affiliateHeaders.organisation'), value: 'affiliated_organisation.name' },
    { text: i18n.t('affiliateHeaders.createdAt'), value: 'created_at' },
    {
      text: i18n.t('affiliateHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function pricingZonesHeaders(i18n) {
  return [
    { text: i18n.t('pricingZonesHeaders.name'), value: 'name' },
    { text: i18n.t('pricingZonesHeaders.code'), value: 'code' },
    {
      text: i18n.t('pricingZonesHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function pricingZoneRelationsHeaders(i18n) {
  return [
    { text: i18n.t('pricingZoneRelationsHeaders.zoneOne'), value: 'zone_one.name' },
    { text: i18n.t('pricingZoneRelationsHeaders.zoneTwo'), value: 'zone_two.name' },
    {
      text: i18n.t('pricingZoneRelationsHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}