<template>
  <v-expansion-panel class="mt-5">
    <v-expansion-panel-header>
      <v-img
        v-if="editedDrivingItem?.gnetData"
        alt="GNET"
        class="shrink mr-3"
        contain
        src="@/assets/gnet-small.png"
        transition="scale-transition"
        height="26px"
        width="28px" />
      <div class="d-flex justify-space-between">
        <div>
          <span class="text-uppercase">{{ $t('gnet.gnetInfo') + ' ' }} </span>
          <strong v-if="editedDrivingItem.gnet_reservation_number">
            [Res. No. {{ editedDrivingItem.gnet_reservation_number }}]
          </strong>
        </div>
        <!-- <strong class="d-flex">
          <v-icon
            :color="getStatusColor(editedDrivingItem?.gnet_reservation_accepted == 1 ? 'accepted' : 'pending')"
            class="me-1 status-circle">
            mdi-circle
          </v-icon>
          {{ getStatus(editedDrivingItem?.gnet_reservation_accepted == 1 ? 'accepted' : 'pending') }}
        </strong> -->
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-text-field
            v-model="editedDrivingItem.gnetData.requester_id"
            clearable
            :label="$t('gnet.requesterId')"
            type="text"
            disabled></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-text-field
            v-model="editedDrivingItem.gnetData.preferred_vehicle_type"
            clearable
            :label="$t('gnet.preferredVehicleType')"
            type="text"
            disabled></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-text-field
            v-model="editedDrivingItem.gnetData.reservation_type"
            clearable
            :label="$t('gnet.reservationType')"
            type="text"
            disabled></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-text-field
            v-model="editedDrivingItem.gnetData.run_type"
            clearable
            :label="$t('gnet.runType')"
            type="text"
            disabled></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12" v-if="editedDrivingItem.gnetData.run_type == 'HOURLY'">
          <v-text-field
            v-model="editedDrivingItem.gnetData.trip_duration_minutes"
            clearable
            :label="$t('gnet.tripDurationMinutes')"
            type="text"
            disabled></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-text-field
            v-model="editedDrivingItem.gnetData.price"
            clearable
            :label="$t('gnet.price')"
            type="text"
            disabled></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12">
          <validation-provider v-slot="{ errors }" name="currency">
            <v-autocomplete
              v-model="editedDrivingItem.gnetData.currency"
              :items="filteredCurrencies"
              clearable
              item-text="name"
              item-value="id"
              :label="$t('gnet.currency')"
              :no-data-text="$t('select.noDataAvailable')"
              disabled
              @focus="$event.target.click()"
              :error-messages="errors"></v-autocomplete>
          </validation-provider>
        </v-col>
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-textarea
            v-model="editedDrivingItem.gnetData.note_driver"
            counter="1000"
            :label="$t('gnet.noteDriver')"
            rows="2"
            auto-grow
            disabled></v-textarea>
        </v-col>
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-textarea
            v-model="editedDrivingItem.gnetData.note_dispatcher"
            counter="1000"
            :label="$t('gnet.noteDispatcher')"
            rows="2"
            auto-grow
            disabled></v-textarea>
        </v-col>
        <v-col
          cols="12"
          lg="12"
          md="12"
          sm="12"
          v-if="
            !gnetDrivingStatusAccepted && !gnetDrivingStatusRejected && editedDrivingItem.gnet_reservation_creator == 0
          "
          class="d-flex justify-end">
          <v-btn class="primary red" text @click="handleDrivingDecision('reject')">
            {{ $t('buttons.reject') }}
          </v-btn>

          <v-btn class="primary ml-5" text @click="handleDrivingDecision('accept')">
            {{ $t('buttons.accept') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>

    <v-dialog v-model="gnetDrivingDecisionModal" max-width="290" persistent>
      <v-card>
        <v-toolbar class="text-h5 primary" dark>
          {{ $t('driving.areYouSure') }}
        </v-toolbar>
        <v-card-text>
          <br />
          {{
            gnetDrivingDecision === 'accept'
              ? $t('driving.acceptDriving')
              : gnetDrivingDecision === 'reject'
              ? $t('driving.rejectDriving')
              : ''
          }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary cancel" text @click="gnetDrivingDecisionModal = false">
            {{ $t('buttons.no') }}
          </v-btn>
          <v-btn class="primary" text @click="saveDrivingDecision" :disabled="loading" :loading="loading">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-expansion-panel>
</template>

<script>
import i18n from '@/i18n/i18n';

export default {
  name: 'GnetInfo',
  components: {},
  props: ['editedDrivingItem', 'filteredCurrencies', 'gnetDrivingStatusAccepted', 'gnetDrivingStatusRejected'],
  data: () => ({
    gnetDrivingDecisionModal: false,
    gnetDrivingDecision: '',
    loading: false,
  }),
  mounted() {},
  created() {},
  computed: {},
  methods: {
    handleDrivingDecision(decision) {
      this.gnetDrivingDecisionModal = true;
      this.gnetDrivingDecision = decision;
    },
    async saveDrivingDecision() {
      this.loading = true;

      await this.$store
        .dispatch('gnet/gnetDrivingDecision', {
          decision: this.gnetDrivingDecision,
          driving_id: this.editedDrivingItem.id,
        })
        .then(() => {
          if (this.gnetDrivingDecision === 'accept') {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.drivingAccepted'), color: 'green' });
            this.$emit('gnetDrivingStatusAccepted');
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.drivingRejected'), color: 'green' });
            this.$emit('gnetDrivingStatusRejected');
          }

          this.gnetDrivingDecisionModal = false;
          this.$store.commit('updatedNewDrivingCounter');
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getStatus(status) {
      const statuses = {
        pending: 'drivingStatus.pending',
        accepted: 'drivingStatus.accepted',
        rejected: 'drivingStatus.rejected',
        canceled: 'drivingStatus.canceled',
      };

      return status ? i18n.t(statuses[status]) : '';
    },
    getStatusColor(status) {
      const statuses = {
        pending: '#ECC600',
        accepted: '#3CB503',
        rejected: '#E1A750',
        canceled: '#868686',
      };

      return status ? i18n.t(statuses[status]) : '';
    },
  },
  watch: {},
};
</script>
